<template>
  <div class="contact-us">
    <div class="header d-flex flex-row">
      <div class="left d-flex flex-column justify-start">
        <h3>Say hello!</h3>
        <p>We are here if you need us!</p>
        <div class="form-container">
          <Form
            class="form"
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <Field
              type="text"
              name="name"
              placeholder="First name *"
              class="form-control"
              :class="{ 'is-invalid': errors.name }"
            />
            <Field
              type="text"
              name="surname"
              placeholder="Last name *"
              class="form-control"
              :class="{ 'is-invalid': errors.surname }"
            />
            <Field
              type="text"
              name="email"
              placeholder="Email*"
              class="form-control"
              :class="{ 'is-invalid': errors.email }"
            />
            <Field
              type="text"
              name="phone"
              placeholder="Phone *"
              class="form-control"
              :class="{ 'is-invalid': errors.phone }"
            />
            <Field
              type="text"
              name="companyName"
              placeholder="Company name (optional)"
              class="form-control input-lg"
              :class="{ 'is-invalid': errors.companyName }"
            />
            <Field
              type="text"
              name="subject"
              placeholder="Subject*"
              class="form-control input-lg"
              :class="{ 'is-invalid': errors.subject }"
            />
            <Field
              type="text"
              name="text"
              placeholder="Write your massage*"
              class="form-control"
              :class="{ 'is-invalid': errors.text }"
              as="textarea"
            />
          </Form>
          <Button :width="250" :backGround="'#000'" :color="'#fff'"
            >SEND</Button
          >
        </div>
      </div>
      <div class="right d-flex flex-column align-center">
        <div class="right__top">
          <h3>Customer Service</h3>
          <p>
            De Bloemenmaker is a young start-up and part of ASD Online BV. We
            are very proud of our company but start small. That is why we do not
            have our own building yet, but we rent part of the building from DFM
            Bloemen & Planten in Oss. Here all our bouquets are made with love
            by the flower makers of the Flower Maker. Of course we like a
            challenge, no order is too big or too crazy for us! We ensure that
            your bouquet (s) always look tip top!
          </p>
          <h4>
            We handle our administration and customer service from various
            places. Our postal address (we do not have a visiting address yet)
            is as follows:
          </h4>
          <h5>De Bloemenmaker Nieuwe Hescheweg 290 5342 NM Oss</h5>
        </div>

        <div class="right__bottom">
          <h3 class="title">Customer service opening hours:</h3>

          <div class="hours">
            <h5 class="hours__title">
              Monday to Friday: 9:00 AM - 5:00 PM Saturday: 9:00 AM - 1:00 PM
              <span>Closed on Sundays and public holidays.</span>
            </h5>
          </div>

          <div class="email">
            <h5 class="email__title">You can reach us via:</h5>
            <h3 class="email__subtitle">info@bloemenmaker.nl</h3>
          </div>

          <div class="phone">
            <h5>Or call:</h5>
            <h3>085-0861012</h3>
          </div>

          <h5 class="z-code">
            KvK number: 78033276 <span>BTW-NUMMER: NL861239805B01</span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

import Button from "@/components/Button";

export default {
  name: "ContactUs",
  components: {
    Form,
    Field,
    Button,
  },
  setup() {
    const schema = Yup.object().shape({
      name: Yup.string().required("Name is required").max(50),
      surname: Yup.string().required("Surname is required").max(50),
      companyName: Yup.string().required("companyName is required").max(50),
      subject: Yup.string().required("subject is required").max(50),
      phone: Yup.string()
        .required("Phone is required")
        .matches(/^\+?([0-9]{10,16})$/, "Phone number should be valid")
        .max(15),
      text: Yup.string().required("Text is required").max(200),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
    });

    const onSubmit = (values, actions) => {
      axios
        .post(
          "", // api
          values
        )
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log("error", e);
        });
      actions.setValues({
        email: "",
        name: "",
        surname: "",
        companyName: "",
        subject: "",
        phone: "",
        text: "",
      });
    };

    return {
      schema,
      onSubmit,
    };
  },
};
</script>

